import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HeartSVG } from '../../svg/HeartAlt.svg';
import Badge from '../ui/Badge';
import { css } from 'linaria';
import { ReactComponent as SaveIcon } from '../../svg/saveAlt2.svg';

const wrapper = css`
  display: flex;
  align-items: center;
`;

const text = css`
  font-family: 'Barlow';
  font-size: 1rem;
  color: white;
  padding-left: 8px;
`;

export function FavouriteCount({ className, listId }) {
  const { count } = useProductList(listId);

  return (
    <Link to="/favourites" className={className}>
      <div className={wrapper}>
        <SaveIcon style={{ height: '1.3em', width: '1.3em', color: 'white' }} />
        {count > 0 && <Badge text={count} />}
        <p className={text}>Min inköpslista</p>
      </div>
    </Link>
  );
}
