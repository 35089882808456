import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';
import { isRelativeUrl } from '@jetshop/core/helpers/isRelativeUrl';

export const baseStyles = `
  background-color: #6cc400;
  border-color: ${theme.colors.black};
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 18px 52px;
  display: inline-block;
  
 
  
  text-transform: uppercase;
  span {
    margin-right: 12px;
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 0em;
  }
  ${theme.above.md} {
    max-width: 100%;
  }

  :hover,
  :active {
    background-color: ${theme.colors.abdDarkGrey};
  }

  &.primary {
    background-color: ${theme.colors.abdGreen};
    :hover,
    :active {
      background-color: ${theme.colors.abdGreenHover};
    }

    :disabled {
      background-color: ${theme.colors.abdTextGrey};
      color: ${theme.colors.mediumgrey};
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: white;
    border: 1.5px solid ${theme.colors.black};
    color: ${theme.colors.darkgrey};
    circle.path {
      stroke: black;
    }

    :disabled {
      background-color: ${theme.colors.abdTextGrey};
      color: ${theme.colors.mediumgrey};
      cursor: not-allowed;
    }
  }

  &.transparent {
    background-color: transparent;
    color: inherit;
  }

  &.fill-width {
    width: 100%;
    padding: 10px 0;
  }

  :disabled {
    background-color: ${theme.colors.abdTextGrey};
    color: ${theme.colors.mediumgrey};
    cursor: not-allowed;
  }

`;

const Button = styled('button')`
  ${baseStyles};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-top: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-bottom: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
`;

const TrendABase = styled('a')`
  ${baseStyles};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-top: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-bottom: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TrendLinkBase = styled(Link)`
  ${baseStyles};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-top: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  padding-bottom: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${theme.below.sm} {
    width: 100%;
  }
`;

export function TrendLink({ to, appearance, className, ...props }) {
  return typeof to === 'string' && !isRelativeUrl(to) ? (
    <TrendABase href={to} {...props} className={cx(appearance, className)} />
  ) : (
    <TrendLinkBase to={to} {...props} className={cx(appearance, className)} />
  );
}

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  appearance,
  fillWidth,
  children,
  ...props
}) => (
  <Button
    {...props}
    className={cx(props.className, appearance, fillWidth && 'fill-width')}
  >
    {loading ? (
      <>
        <span>{loadingText}</span>
        <StyledSpinner />
      </>
    ) : (
      children
    )}
  </Button>
);
export default ButtonWithLoading;
