import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FacebookIcon } from '../../../assets/svg/Facebook.svg';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0;
  color: #ffffff;

  ${theme.below.lg} {
    margin-top: -120px;
  }

  span {
    font-size: 0.875rem;
    font-weight: 500;
  }

  ul {
    ${theme.above.md} {
      margin-top: 0;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  li {
    margin: 0 0.5rem;
  }

  a.social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const LogoWrapper = styled('span')`
  background: none;
  width: 1.375rem;
  height: 1.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${theme.below.md} {
    margin-right: 0.25rem;
  }
  svg {
    max-height: 80%;
  }
`;

const SocialLinks = () => (
  <Wrapper>
    <span>Följ oss:</span>
    <ul>
      <li>
        <a
          className="social-links"
          aria-label="Facebook"
          target={'_blank'}
          rel="noreferrer"
          href="https://www.facebook.com/allabildelar.se/"
        >
          <LogoWrapper>
            <FacebookIcon />
          </LogoWrapper>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
