import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';

const Container = styled('div')`
  color: white;
`;

const Wrapper = styled('div')`
  color: inherit;
  font-size: 14px;
  background-color: ${theme.colors.abdGreen};
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border: 0;
  border-radius: 50%;
  line-height: 0.9rem;
  background-color: ${theme.colors.abdGreen};
  color: ${theme.colors.white};
  overflow: hidden;
  position: absolute;
  top: -5px;
  right: 10px;

  ${theme.below.sm} {
    right: 30px;
    height: 18px;
    width: 18px;
    top: -3px;
  }

  span {
    height: 0.9rem;
    width: 0.9rem;
    border: 0;
    border-radius: 50%;
    font-size: 0.5rem;
    line-height: 0.9rem;
    color: ${theme.colors.white};
    background-color: ${theme.colors.abdGreen};
    overflow: hidden;
  }
`;

const BadgeMobile = ({ text }) => {
  return (
    <Container>
      <Above breakpoint="sm">
        <Wrapper>
          <div>{text}</div>
        </Wrapper>
      </Above>
      <Below breakpoint="sm">
        <SmallWrapper className="badge">
          <div>{text}</div>
        </SmallWrapper>
      </Below>
    </Container>
  );
};

export default BadgeMobile;
