import React from 'react';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import useChildren from '../../../hooks/useChildren';
import Link from '../../ui/Link';

const NavigationLinksContainer = styled('section')`
  z-index: 999;
  flex: 1 1 40%;
  padding: 0 3rem;

  ${theme.below.xl} {
    margin-left: 0px;
  }

  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-position: inside;
  }

  li {
    padding-top: 16px;
  }

  h2 {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 28px;
    width: 100%;

    ${theme.below.sm} {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
  }

  ${theme.below.lg} {
    padding: 0;
  }

  ${theme.below.sm} {
    padding: 0;
    ul {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      list-style-position: inside;
    }

    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;
const GreenLine = styled('div')`
  background: #6cc400;
  width: 21px;
  height: 2px;

  ${theme.below.sm} {
    width: 40px;
    height: 4px;
  }
`;
const FooterNavLinks = props => {
  const { footerNavigationLinks } = useChildren(props);
  if (!Array.isArray(footerNavigationLinks) && !(footerNavigationLinks > 0))
    return null;
  return (
    <NavigationLinksContainer>
      <h2>{t('Webbplatsinformation')}</h2>
      {/* <GreenLine /> */}
      <ul>
        {footerNavigationLinks?.map(
          ({ internalLinkUrl, externalLinkUrl, linkTitle }) => {
            return (
              <li key={linkTitle}>
                <Link
                  internalLink={internalLinkUrl}
                  externalLink={externalLinkUrl}
                >
                  {linkTitle}
                </Link>
              </li>
            );
          }
        )}
      </ul>
    </NavigationLinksContainer>
  );
};

export default FooterNavLinks;
