import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import { theme } from '../../../Theme';
import { css } from 'linaria';
import { Link } from 'react-router-dom';
import SubMenuWrapperAlt from './SubMenuWrapperAlt';

const Wrapper = styled('div')`
  height: 100%;
  font-family: 'Barlow';

  .level-1-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    height: 72px;
    cursor: pointer;

    @media (min-width: 1920px) {
      margin-left: 0px;
    }

    ${theme.below.xl} {
      margin-left: 16px;
    }
  }
`;

const MainMenuItem = styled('li')`
  a {
    font-weight: 700;
    font-size: 1rem;
    line-height: 14px;
    position: relative;
    padding-right: 4rem;
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #ffffff !important;
      text-decoration: underline;
    }
  }
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
`;

const CustomLinkWrapper = styled('div')``;

const CustomLink = styled(Link)`
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 700;
  line-height: 14px;
  color: #ffffff;
  padding-right: 4rem;
  text-decoration: none;

  &:last-child {
    padding-right: 0; /* Set it to 0 to remove the padding */
  }

  &:hover {
    color: #ffffff !important;
    text-decoration: underline;
  }
`;

const NavRoute = styled('a')`
  font-family: 'Barlow';
  font-weight: 700;
  color: white;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: white !important;
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <Container>
      <UIMenuContainer>
        {props => {
          return (
            <Wrapper onMouseLeave={props.clearActiveCategories}>
              <ul className={'level-1-list'}>
                {queryData &&
                  queryData.map(cat => (
                    <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                  ))}
                {/* <NavRoute href="/bilmarken-1" alt="">
                  Bilmärken
                </NavRoute> */}
              </ul>

              {props.activeCategories.length > 0 &&
                props.activeCategories[0].subcategories.length > 0 &&
                (props.activeCategories[0].name === 'Bilmärken' ? (
                  <SubMenuWrapperAlt
                    pose={
                      props.activeCategories.length > 0 &&
                      props.activeCategories[0].subcategories.length > 0
                        ? 'open'
                        : 'closed'
                    }
                    activeCategory={props.activeCategories[0]}
                    closeNav={props.clearActiveCategories}
                  />
                ) : (
                  <SubMenuWrapper
                    pose={
                      props.activeCategories.length > 0 &&
                      props.activeCategories[0].subcategories.length > 0
                        ? 'open'
                        : 'closed'
                    }
                    activeCategory={props.activeCategories[0]}
                    closeNav={props.clearActiveCategories}
                  />
                ))}
            </Wrapper>
          );
        }}
      </UIMenuContainer>
    </Container>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  if (!cat) return null;

  let displayName = cat?.name;

  if (
    displayName &&
    displayName.includes('Bil') &&
    displayName !== 'Bilmärken'
  ) {
    displayName = displayName.replace('Bil', 'Reservdelar');
  }

  return (
    <>
      <MainMenuItem
        // When mousing over a menu item, set it as the active nav
        onMouseEnter={() => props.setActiveCategory(cat)}
      >
        <CategoryLink
          // When following a category link, close the menu
          onClick={props.clearActiveCategories}
          category={cat}
        >
          {displayName}
        </CategoryLink>
      </MainMenuItem>
    </>
  );
};

export default CategoryMenuContainer;
