import React from 'react';
import { Link } from 'react-router-dom';
import { Below } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ReactComponent as AllabildelarLogo } from '../../../assets/svg/Logo.svg';

import { theme } from '../../Theme';

const StoreName = styled('div')`
  svg {
    width: 150px;

    ${theme.below.md} {
      width: 100px;
    }
  }
`;

const Logo = () => (
  <>
    <StoreName>
      <Link to="/">
        <AllabildelarLogo />
      </Link>
    </StoreName>
  </>
);

export { Logo };
